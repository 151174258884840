import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  SelectField,
  ReferenceArrayField,
  ShowButton,
  EditButton,
  DeleteButton,
  Filter,
  TextInput,
  SelectInput,
  TopToolbar,
  CreateButton,
} from "react-admin";

import { AVAILABLE_PREPARATIONS_CHATS, ROLE_ADMIN, ROLE_TEACHER, ROLE_STUDENT } from "../../config";

const LengthField = props => props.total;

const ChatFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" source="name" alwaysOn />
        <SelectInput label="Préparation" source="preparation" alwaysOn choices={AVAILABLE_PREPARATIONS_CHATS} />
    </Filter>
);

const Actions = () => (
    <TopToolbar>
        <CreateButton resource="messages" label="Créer un message" />
        <CreateButton resource="chats" label="Créer" />
    </TopToolbar>
);

const ChatList = props => (
    <List {...props} filters={<ChatFilter />} actions={<Actions />}>
        <Datagrid>
            <TextField label="Nom" source="name" />
            <SelectField label="Préparation" source="preparation" choices={AVAILABLE_PREPARATIONS_CHATS} />
            <ReferenceArrayField label="Elèves" reference="USERS_V2" source="members" filter={{ role: ROLE_STUDENT }}>
                <LengthField />
            </ReferenceArrayField>
            <ReferenceArrayField label="Profs" reference="USERS_V2" source="members" filter={{ role: ROLE_TEACHER }}>
                <LengthField />
            </ReferenceArrayField>
            <ReferenceArrayField label="Admin" reference="USERS_V2" source="members" filter={{ role: ROLE_ADMIN }}>
                <LengthField />
            </ReferenceArrayField>
            <ShowButton label="" />
            <EditButton label="" />
            <DeleteButton label="" undoable={false} redirect={false}/>
        </Datagrid>
    </List>
);

export default ChatList;
