import './App.css';
import { Admin, Resource } from "react-admin";
import { NotificationList, NotificationShow, NotificationCreate, NotificationEdit } from "./Components/Notifications";
import { FirebaseDataProvider, FirebaseAuthProvider } from "react-admin-firebase";
import NotificationsIcon from '@mui/icons-material/Notifications';

import { env as config } from "./config";
import users from './Components/Users';
import messages from './Components/Messages';
import chats from './Components/Chats';
import utils from './Components/Utils';

import { ROLE_ADMIN } from "./config";
const options = {
  logging: true,
  metaFieldCasing: 'camel',
  persistence: 'local',
  firestoreCostsLogger: {
    enabled: false,
  },
};

const dataProvider = FirebaseDataProvider(config, options);
const authProvider = FirebaseAuthProvider(config, options);

const myAuthProvider = {
  ...authProvider,
  login: async params => {
    const user = await authProvider.login(params);
    const claims = await authProvider.getPermissions();

    if (claims.role === ROLE_ADMIN) {
      localStorage.setItem('userData', JSON.stringify(claims));
      return user;
    }

    await authProvider.logout();
    throw new Error("Erreur de connexion");
  },
  logout: async () => {
    localStorage.removeItem('userData');
    await authProvider.logout();
    return Promise.resolve();
  },
  checkError: error => {
    const status = error.status;
    if (status === 401 || status === 403) {
        return Promise.reject();
    }

    return Promise.resolve();
  }
};

const App = () => {
  return (
    <Admin
      title="CapAvocat Admin"
      dataProvider={dataProvider}
      authProvider={myAuthProvider}
    >
      <Resource name="USERS_V2" {...users} />
      <Resource name="MESSAGES" {...messages} />
      <Resource name="CHATS_V2" {...chats} />
      <Resource name="UTILS" {...utils} />

      <Resource
        name="NOTIFICATIONS"
        list={NotificationList}
        show={NotificationShow}
        create={NotificationCreate}
        edit={NotificationEdit}
        icon={NotificationsIcon}
        options={{ label: 'Notifications' }}
      />
    </Admin>
  );
}

export default App;
