import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    BooleanField,
    TextField,
    EmailField,
    SelectField,
    Datagrid,
    ReferenceArrayField,
} from "react-admin";

import { AVAILABLE_ROLES, AVAILABLE_PREPARATIONS_CHATS } from "../../config";

const UserShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <BooleanField label="Actif" source="enabled" valueLabelTrue="Oui" valueLabelFalse="Non" />
            <BooleanField label="Push notifications" source="allowPushNotifications" valueLabelTrue="Oui" valueLabelFalse="Non"/>
            <TextField label="Code étudiant" source="code" />
            <TextField label="Nom" source="lastName" />
            <TextField label="Prénom" source="firstName" />
            <EmailField label="Email" source="email" />
            <TextField label="Téléphone" source="phoneNumber" />
            <SelectField label="Rôle" source="role" choices={AVAILABLE_ROLES} />
            <ReferenceArrayField label="Chats" source="chats" reference="CHATS_V2" sort={{ field: 'preparation', order: 'ASC' }}>
                <Datagrid>
                    <TextField label="Nom" source="name" />
                    <SelectField label="Préparation" source="preparation" choices={AVAILABLE_PREPARATIONS_CHATS} />
                </Datagrid>
            </ReferenceArrayField>
        </SimpleShowLayout>
    </Show>
);

export default UserShow;
