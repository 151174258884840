import * as React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    required,
    useNotify,
    useRefresh,
} from "react-admin";

import { AVAILABLE_PREPARATIONS_CHATS } from "../../config";

const ChatCreate = props => {
    const notify = useNotify();
    const refresh = useRefresh();
    const onSuccess = ({ data }) => {
      notify(`Le chat "${data.name}" est créé`);
      refresh();
    };
  
    return (
      <Create {...props} onSuccess={onSuccess}>
        <SimpleForm>
          <TextInput source="name" validate={[required()]} />
          <SelectInput label="Préparation" source="preparation" validate={[required()]} choices={AVAILABLE_PREPARATIONS_CHATS} />
        </SimpleForm>
      </Create>
    );
  };

export default ChatCreate;
