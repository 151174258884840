import * as React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    required,
} from "react-admin";

import { AVAILABLE_PREPARATIONS_CHATS } from "../../config";

const ChatEdit = props => (
    <Edit {...props}>
        <SimpleForm redirect="list">
            <TextInput source="name" validate={[required()]} />
            <SelectInput label="Préparation" source="preparation" validate={[required()]} choices={AVAILABLE_PREPARATIONS_CHATS} />
        </SimpleForm>
    </Edit>
);

export default ChatEdit;
