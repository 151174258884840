import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

import MessageCreate from './MessageCreate';

export default {
  create: MessageCreate,
  icon: AssignmentIndIcon,
  options: {
    label: 'Messages',
  },
};
