export const env = {
    name: "RELEASE",
    apiKey: "AIzaSyBgVeSjqL4133aYH5BMn215NAt9oNLTBPc",
    authDomain: "capavocat-fba65.firebaseapp.com",
    databaseURL: "https://capavocat-fba65.firebaseio.com",
    projectId: "capavocat-fba65",
    storageBucket: "capavocat-fba65.appspot.com",
    messagingSenderId: "877523519106",
    appId: "1:877523519106:web:1aa3ade138c7697d3f969b"
};
