import * as React from "react";
import {
    useCreate,
    Create,
    SimpleForm,
    SelectInput,
    ReferenceInput,
    FormDataConsumer,
    TextInput,
    useRedirect,
    useNotify,
} from "react-admin";

import { AVAILABLE_PREPARATIONS_CHATS } from "../../config";

const MessageCreate = props => {
    const redirect = useRedirect();
    const notify = useNotify();
    const [create] = useCreate();
    const currentUser = JSON.parse(localStorage.getItem('userData'));

    const postSave = async values => {
        try {
            await create('CHATS/' + values.chat + '/MESSAGES',
            {
                data: {
                    ...values,
                    createdAt: new Date(),
                    replying: null,
                    chat: {
                        _id: values.chat,
                    },
                    user: {
                        _id: currentUser.user_id,
                    },
                },
            });
            redirect('/CHATS');
            notify('Message envoyé');
        } catch (error) {
            notify(`Une erreur est survenue`, { type: 'warning' })
            return error;
        }
    };

    return (
        <Create {...props}>
            <SimpleForm onSubmit={postSave}>
                <SelectInput
                    label="Préparation"
                    source="preparation"
                    choices={AVAILABLE_PREPARATIONS_CHATS}
                />

                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        <ReferenceInput
                            label="Thématique"
                            reference="CHATS"
                            source="chat"
                            sort={{ field: 'name', order: 'ASC' }}
                            filter={{ preparation: formData.preparation }}
                        >
                            <SelectInput optionText="name" translateChoice={false} />
                        </ReferenceInput>
                    )}
                </FormDataConsumer>

                <TextInput source="text" multiline="true" />
            </SimpleForm>
        </Create>
    )
};

export default MessageCreate;
