export * from "./env";
export const AVAILABLE_ROLES = [
    { id: 0, name: 'Élève' },
    { id: 1, name: 'Prof' },
    { id: 2, name: 'Admin' },
];
export const AVAILABLE_PREPARATIONS_CHATS = [
    { id: 0, name: 'Annuelle 2020' },
    { id: 1, name: 'Estivale 2020' },
    { id: 2, name: 'Commun 2020' },
    { id: 3, name: 'Annuelle 2021' },
    { id: 4, name: 'Estivale 2021' },
    { id: 5, name: 'Commun 2021' },
    { id: 6, name: 'Annuelle 2022' },
    { id: 7, name: 'Estivale 2022' },
    { id: 8, name: 'Commun 2022' },
    { id: 9, name: 'Article 99 & 100 2022' },
    { id: 10, name: 'Annuelle 2023' },
    { id: '10_common', name: 'Commun 2023' },
    { id: 11, name: '23 ARTICLE 100 AVRIL' },
    { id: 12, name: 'Estivale 2023' },
    { id: 13, name: '23 ARTICLE 100 NOVEMBRE' },
    { id: 14, name: 'Annuelle 2024' },
    { id: '14_common', name: 'Commun 2024' },
    { id: 15, name: '24 ARTICLE 100 AVRIL' },
    { id: 16, name: 'Estivale 2024' },
    { id: 17, name: '2024 ARTICLE 100 NOVEMBRE' },
];
export const PREPARATIONS_TO_SHOW = [14, '14_common', 15, 16, 17];
export const ROLE_STUDENT = 0;
export const ROLE_TEACHER = 1;
export const ROLE_ADMIN = 2;
