import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  DeleteButton,
  RichTextField,
  ReferenceField,
  ReferenceInput,
  FunctionField,
  SelectInput,
  required,
  useNotify,
  useRefresh,
  DateInput,
  DateField,
} from "react-admin";
import { RichTextInput } from 'ra-input-rich-text';

export const NotificationList = (props) => (
  	<List {...props}>
    	<Datagrid>
			<TextField label="Titre" source="title" />
			<RichTextField label="Message" source="text" />
			<ReferenceField label="Créé par" source="user._id" reference="USERS">
				<FunctionField
					label="Prénom Nom"
					render={record => `${record.firstName} ${record.lastName}`}
				/>
			</ReferenceField>
			<DateField label="Date de fin" source="endAt" />
			<DateField label="Date de création" source="createdAt" />
			<ShowButton label="" />
			<EditButton label="" />
			<DeleteButton label="" undoable={false} redirect={false}/>
		</Datagrid>
	</List>
);

export const NotificationShow = props => (
	<Show {...props}>
    	<SimpleShowLayout>
		<TextField label="Titre" source="title" />
		<RichTextField label="Message" source="text" />
		<ReferenceField label="Créé par" source="user._id" reference="USERS">
			<FunctionField
				label="Prénom Nom"
				render={record => `${record.firstName} ${record.lastName}`}
			/>
		</ReferenceField>
		<DateField label="Date de fin" source="endAt" />
		<DateField label="Date de création" source="createdAt" />
		</SimpleShowLayout>
	</Show>
);

export const NotificationCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const onSuccess = ({ data }) => {
    notify(`La notification "${data.name}" est créée`);
    refresh();
  };

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <TextInput label="Titre" source="title" validate={[required()]} />
        <RichTextInput label="Message" source="text" validate={[required()]} />
		<ReferenceInput label="Crée par" source="user._id" reference="USERS">
			<SelectInput optionText={record => `${record.firstName} ${record.lastName}`} initialValue={props.user} />
		</ReferenceInput>
        <DateInput label="Date de fin" source="endAt" />
      </SimpleForm>
    </Create>
  );
};

export const NotificationEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="list">
        <TextInput type="text" label="Titre" source="title" validate={[required()]} />
        <RichTextInput label="Message" source="text" validate={[required()]} />
		<ReferenceInput label="Crée par" source="user._id" reference="USERS">
			<SelectInput optionText={record => `${record.firstName} ${record.lastName}`} initialValue={props.user} />
		</ReferenceInput>
        <DateInput label="Date de fin" source="endAt" />
    </SimpleForm>
  </Edit>
);
