import PeopleIcon from '@mui/icons-material/People';
import UserCreate from './UserCreate';
import UserEdit from './UserEdit';
import UserList from './UserList';
import UserShow from './UserShow';

export default {
  create: UserCreate,
  edit: UserEdit,
  list: UserList,
  show: UserShow,
  icon: PeopleIcon,
  options: {
    label: 'Utilisateurs',
  },
};
