import React, { useState } from 'react';
import { Button, SimpleForm, TextInput, useNotify, useRefresh } from 'react-admin';
import { Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@mui/material';
import { functions, httpsCallable } from '../../firebase';

const callForceUpdateChatMembers = async (filters) => {
    try {
        const forceUpdateChatMembers = httpsCallable(functions, 'users-forceUpdateChatMembers');
        const result = await forceUpdateChatMembers({ filters });
        return result.data;
    } catch (error) {
        console.error('Error calling Cloud Function:', error);
        throw error;
    }
};

const ForceUpdateButton = () => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({ role: '', email: '', preparation: '' });
    const notify = useNotify();
    const refresh = useRefresh();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await callForceUpdateChatMembers(filters);
            notify('Chat members updated successfully for the filtered users', { type: 'success' });
            refresh();
        } catch (error) {
            console.error('Error updating chat members:', error);
            notify('Error updating chat members', { type: 'warning' });
        }
        setLoading(false);
        handleClose();
    };

    return (
        <>
            <Button onClick={handleClickOpen} label="Force Update Chat Members" />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Force Update Chat Members</DialogTitle>
                <DialogContent>
                    <SimpleForm>
                        <TextInput
                            source="role"
                            label="Role"
                            value={filters.role}
                            onChange={handleInputChange}
                        />
                        <TextInput
                            source="email"
                            label="Email"
                            value={filters.email}
                            onChange={handleInputChange}
                        />
                        <TextInput
                            source="preparation"
                            label="Preparation"
                            value={filters.preparation}
                            onChange={handleInputChange}
                        />
                    </SimpleForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} label="Cancel" />
                    <Button onClick={handleSubmit} label="Submit" disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : ''}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ForceUpdateButton;
