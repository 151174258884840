import * as React from "react";
import {
  Datagrid,
  List,
  BooleanField,
  TextField,
  SelectField,
  ReferenceArrayField,
  Filter,
  TextInput,
  ShowButton,
  EditButton,
  DeleteButton,
} from "react-admin";

import { AVAILABLE_ROLES, AVAILABLE_PREPARATIONS_CHATS } from "../../config";

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="email" alwaysOn />
    </Filter>
);

const UserList = props => (
    <List {...props} filters={<UserFilter />}>
        <Datagrid>
            <BooleanField label="Actif" source="enabled" valueLabelTrue="Oui" valueLabelFalse="Non" />
            <TextField label="Code étudiant" source="code" />
            <TextField label="Nom" source="lastName" />
            <TextField label="Prénom" source="firstName" />
            <TextField label="Téléphone" source="phoneNumber" />
            <SelectField label="Rôle" source="role" choices={AVAILABLE_ROLES} />
            <ReferenceArrayField label="Chats" source="chats" reference="CHATS_V2" sort={{ field: 'preparation', order: 'ASC' }}>
                <Datagrid>
                    <TextField label="Nom" source="name" />
                    <SelectField label="Préparation" source="preparation" choices={AVAILABLE_PREPARATIONS_CHATS} />
                </Datagrid>
            </ReferenceArrayField>
            <ShowButton label="" />
            <EditButton label="" />
            <DeleteButton undoable={false} redirect={false}/>
        </Datagrid>
    </List>
);

export default UserList;
