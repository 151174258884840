import SettingsIcon from '@mui/icons-material/People';
import ForceUpdateButton from './ForceUpdateButton';

export default {
  list: <ForceUpdateButton />,
  icon: SettingsIcon,
  options: {
    label: 'Utilitaires',
  },
};
