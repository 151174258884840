import GroupWorkIcon from '@mui/icons-material/GroupWork';
import ChatCreate from './ChatCreate';
import ChatEdit from './ChatEdit';
import ChatList from './ChatList';
import ChatShow from './ChatShow';

export default {
  create: ChatCreate,
  edit: ChatEdit,
  list: ChatList,
  show: ChatShow,
  icon: GroupWorkIcon,
  options: {
    label: 'Thématiques',
  },
};
