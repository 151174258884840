import * as React from "react";
import {
    Edit,
    SimpleForm,
    BooleanInput,
    TextInput,
    SelectInput,
    FormDataConsumer,
    ReferenceArrayInput,
    PasswordInput,
    AutocompleteArrayInput,
    required,
    regex,
} from "react-admin";

import { AVAILABLE_ROLES, AVAILABLE_PREPARATIONS_CHATS, PREPARATIONS_TO_SHOW, ROLE_ADMIN } from "../../config";

const validatePhoneNumber = regex(/^((\+)[0-9][0-9]|0)[1-9](\d{2}){4}$/, 'Numéro de téléphone non valide');

const UserEdit = props => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <BooleanInput label="Compte actif" source="enabled" defaultValue={true} />
                <BooleanInput label="Activer push notifications" source="allowPushNotifications" defaultValue={true} />
                <TextInput label="Nom" source="lastName" validate={[required()]} />
                <TextInput label="Prénom" source="firstName" validate={[required()]} />
                <TextInput label="Email" source="email" validate={[required()]} />
                <TextInput label="Téléphone"
                    source="phoneNumber"
                    type="tel"
                    validate={[required(), validatePhoneNumber]}
                />
                <SelectInput label="Rôle" source="role" validate={[required()]} choices={AVAILABLE_ROLES} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.role >= ROLE_ADMIN &&
                        <PasswordInput label="Mot de passe" source="password" validate={[required()]} />
                    }
                </FormDataConsumer>
                <ReferenceArrayInput
                    fullWidth
                    label="Chats"
                    source="chats"
                    reference="CHATS_V2"
                    perPage={500}
                    sort={{ field: 'name', order: 'ASC' }}
                    filter={{collectionQuery: c => c.where("preparation", "in", PREPARATIONS_TO_SHOW)}}
                >
                    <AutocompleteArrayInput
                        row={false}
                        optionText={choice => {
                            let preparationName = null;
                            AVAILABLE_PREPARATIONS_CHATS.map(data => {
                                if (choice.preparation === data.id) {
                                    preparationName = data.name;
                                }
                            });
                            return `${choice.name} (${preparationName})`;
                        }}
                    />
                </ReferenceArrayInput>
            </SimpleForm>
        </Edit>
    )
};

export default UserEdit;
