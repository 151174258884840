import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    SelectField,
    Datagrid,
    ReferenceArrayField,
} from "react-admin";

import { AVAILABLE_PREPARATIONS_CHATS, ROLE_TEACHER, ROLE_STUDENT } from "../../config";

const ChatShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <SelectField label="Préparation" source="preparation" choices={AVAILABLE_PREPARATIONS_CHATS} />
            <ReferenceArrayField label="Profs" reference="USERS_V2" source="members" filter={{ role: ROLE_TEACHER }}>
                <Datagrid>
                    <TextField label="Prénom" source="firstName" />
                    <TextField label="Nom" source="lastName" />
                </Datagrid>
            </ReferenceArrayField>
            <ReferenceArrayField label="Elèves" reference="USERS_V2" source="members" filter={{ role: ROLE_STUDENT }}>
                <Datagrid>
                    <TextField label="Prénom" source="firstName" />
                    <TextField label="Nom" source="lastName" />
                </Datagrid>
            </ReferenceArrayField>
        </SimpleShowLayout>
    </Show>
);

export default ChatShow;
